<div class="row">
  <div class="col-12">
    <div class="jumbotron">
      <h1 class="h1">Terms and Conditions of Use Agreement <small>August 4, 2020</small></h1>
      <p class="lead text-justify">
        The following Terms and Conditions of Use Agreement (hereinafter referred to as "Agreement")
        describes the terms and conditions of use for any individual or entity (hereinafter referred
        to as "Customer") using this Remote Access Service offered by Syscon, Inc. (hereinafter
        referred to as "Syscon" or "Service"). By using the Service, Customer indicates its accept
        of this agreement.
      </p>
    </div>
  </div>
  <div class="col-12">
    <h2>1. Changes to the Agreement</h2>
    <p>
      Customer acknowledges and agrees that Syscon reserves the right to change the Agreement at any time
      and without notice. Syscon shall post any revised Agreement online and any such revised Agreement
      shall control the use of the Service subsequent to such posting. Customer is solely responsible to
      know and follow the requirements of the Agreement. Use of the Service constitutes Customer acceptance
      of the then current version of the Agreement.
    </p>
  </div>
  <div class="col-12">
    <h2>2. Use of the site</h2>
    <p>
      This Web site (https://court.today/) and its contents (the "Content") are intended for customers of Syscon, Inc.
      You may not use this Web site or the Content for any purpose not related to your business with Syscon, Inc.
      You are specifically prohibited from: (a) downloading, copying, or re-transmitting any or all of the Web site
      or the Content without, or in violation of, a written license or agreement with Syscon; (b) using any data mining,
      robots or similar data gathering or extraction methods; (c) manipulating or otherwise displaying the Web site or
      the Content by using framing or similar navigational technology; (d) registering, subscribing, unsubscribing, or
      attempting to register, subscribe, or unsubscribe any party for any Syscon product or service if you are not
      expressly authorized by such party to do so; and (e) using the Web site or the Content other than for its
      intended purpose, as determined solely in Syscon's discretion, including but not limited to, to defame, abuse,
      harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy) of others, and/or to
      publish, post, distribute or disseminate any defamatory, infringing, obscene, pornographic, sexual, indecent
      or unlawful material or information.
    </p>
    <p>
      You may not interfere with the security of, or otherwise abuse this Web site or any system resources, services
      or networks connected to or accessible through this Web site. You may only use this Web site for lawful purposes.
    </p>
  </div>
  <div class="col-12">
    <h2>3. Internet software and computer viruses</h2>
    <p>
      Unforeseen technical difficulties with the Internet, Internet software or transmission problems could produce
      inaccurate or incomplete copies of information contained on this Web site. Computer viruses or other destructive
      programs may also be inadvertently downloaded from this Web site.
    </p>
    <p>
      Syscon, Inc. shall not be responsible or liable for any software, computer viruses or other destructive,
      harmful or disruptive files or programs that may infect or otherwise impact your use of your computer
      equipment or other property on account of your access to, use of, or browsing on this Web site or your
      downloading of any of the Materials from this Web site. Syscon recommends that you install appropriate
      anti-virus or other protective software.
    </p>
  </div>
  <div class="col-12">
    <h2>4. Description of Service</h2>
    <p>
      The Syscon service consists of an Internet-based system for remotely accessing public records. The Service is
      intended as a convenience for customers who wish to avoid the necessity of visiting the office where the
      records are located.
    </p>
  </div>
  <div class="col-12">
    <h2>5. License and Use</h2>
    <p>
      Syscon hereby provides Customer a non-exclusive, non-transferable limited license for use of its Service.
      Customer is responsible for any hardware, software, communications equipment or other services that are required
      to access the Syscon service. This includes, but is not limited to, any connection costs for Internet access.
      While Syscon will make every reasonable effort to allow continuous access to the Service, Syscon reserves the
      right at any time and from time to time to modify or discontinue the Service. Customer agrees that Syscon will
      not be liable for any damages claimed by Customer as a result of any disruption, cancellation, or failure of
      the Service. Customer acknowledges that access to the Syscon service is through the Internet and/or through
      telephony devices which are not controlled by Syscon and Syscon shall not be responsible or liable to Customer
      with regard to the unavailability of Service as a result of any failure of the Internet or any third party
      providers of telephony or other services.
    </p>
  </div>
  <div class="col-12">
    <h2>6. Customer's Obligations Regarding Use of Service</h2>
    <p>
      Customer shall be solely responsible for insuring that any of its legal obligations relating to any transaction
      are handled in an appropriate and timely manner. Customer agrees that Syscon will not be held liable for any
      damages relating in any manner to Customer's use of the Service. Customer agrees not to attempt to gain
      unauthorized access to the Syscon Service or to interfere with or disrupt the Service in any way.
    </p>
    <p>
      Customer agrees that if Customer violates any of the above representations, warranties or obligations, that
      Customer will indemnify and hold harmless Syscon against any loss suffered by Syscon or any third party, as
      well as any claims or action arising from any unlawful or improper use of the Syscon service. Such indemnification
      shall include reasonable attorney's fees incurred by Syscon in defending its rights or any third party action
      resulting from Customer's acts or omissions. Customer agrees not to reproduce, copy or resell for any purpose
      any portion of the Syscon service. Customer acknowledges and agrees that there is certain proprietary and
      confidential information regarding delivery of the Service that may be protected by intellectual property laws
      and that Customer has a limited use license that is not transferable to another party without the written consent
      of Syscon.
    </p>
  </div>
  <div class="col-12">
    <h2>7. Web Site and Materials Provided "As Is"</h2>
    <p>
      THIS WEB SITE AND THE MATERIALS ARE PROVIDED "AS IS" WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND,
      EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS
      OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. SYSCON, INC. DOES NOT REPRESENT OR WARRANT THAT THIS
      WEB SITE OR THE MATERIALS WILL MEET YOUR REQUIREMENTS OR THAT THEIR USE WILL BE UNINTERRUPTED OR ERROR FREE.
    </p>
  </div>
  <div class="col-12">
    <h2>8. Limitation of Liability and Limited Warranty</h2>
    <p>
      Syscon shall not be held liable for any indirect, consequential, exemplary, special, incidental, personal
      injury or punitive damages, (including without limitation, lost business, revenue, profits, or goodwill)
      arising in connection with this agreement or the services hereunder (including any Service implementation
      delays/failures), under any theory of tort, contract, warranty, strict liability, negligence, or any other
      legal or equitable theory, even if the party has been advised of or knew or should have known of the possibility
      of such potential damages.
    </p>
    <p>
      Each party acknowledges and accepts the reasonableness of the foregoing disclaimer and limitations of liability.
      No cause of action under any theory which accrued more than one (1) year prior to the institution of a legal
      proceeding alleging such cause of action may be asserted by either Party against the other. For purposes of this
      Section, all references to Syscon and Customer include their respective Affiliates, End Users, agents, officers,
      directors, shareholders, employees, and Service Providers.
    </p>
    <p>
      Because of the difficulty in ascertaining damages or even the true cause of such damages, it is agreed that
      Syscon's liability to the Customer for any losses or damages, whether direct or indirect arising out of the
      Agreement, shall in no case exceed the convenience fees paid to Syscon any transaction in dispute.
    </p>
    <p>
      THERE IS NO OTHER WARRANTY, EXCEPT FOR THE EXPRESS WARRANTIES AND UNDERTAKINGS SET FORTH HEREIN. CUSTOMER'S
      USE OF THE SERVICE IS AT ITS OWN RISK. SYSCON, ON BEHALF OF ITSELF AND SUBCONTRACTORS, IF ANY, DOES NOT MAKE
      AND HEREBY DISCLAIMS, ANY AND ALL OTHER EXPRESS AND/OR IMPIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY
      IMPIED WARRANTIES OF MERCHANTABILITY, OR IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, OR ANY IMPLIED
      WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE. SYSCON DOES NOT WARRANT THAT THE SERVICE
      WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.
    </p>
    <p>
      This limited warranty gives you specific legal rights; you may have other rights that vary from state/jurisdiction
      to state/jurisdiction. Additionally, some states/jurisdictions do not allow the exclusion or limitation of
      liability for consequential or incidental damages, in which event certain of the above limitations may not
      apply to you.
    </p>
    <p>
      Customer is solely responsible for any third party claims arising from Customer's use of the Service and agrees
      to indemnify and hold Syscon, including its subsidiaries, affiliates, officers, directors, associates, content
      providers, and employees, harmless from any claim, demand, or damage, including reasonable attorney's fees,
      asserted by any third party due to or arising out of Customer's use of the Service. Customer is solely responsible
      for any damage done to Customer's computer or any other computer Customer uses to access the Service and for any
      loss or damage to data that results from the use of the Service.
    </p>
    <p>
      Customer fully understands and acknowledges that the performance of the Syscon service is solely based on the
      total performance of communication providers including: telephone providers, circuits, switches, Internet
      backbone carrier lines, connections, hubs, interchanges and routers which are owned or operated by third
      parties; and any or all of which may, at any time, be unavailable or overburdened by network traffic. Therefore,
      Customer agrees that Syscon cannot and will not assume any liability related to the delay or failure of
      delivery of data through the Service due to the above or any other reasons such as interception or interruption
      of data delivery. This section shall supercede any paragraph or any section of the Agreement that may be deemed
      inconsistent with it.
    </p>
  </div>
  <div class="col-12">
    <h2>9. Merger/Jurisdiction</h2>
    <p>
      This agreement is the entire agreement between Customer and Syscon and supersedes any prior agreements or
      understandings, either written or oral. The agreement will be governed in all respects by the laws of the
      United States and the State of Alabama. Customer consents to the exclusive jurisdiction of the Alabama State
      courts and U.S. Federal courts within the State of Alabama for any dispute arising out of this Agreement. This
      Agreement may not be assigned or sublicensed by Customer without Syscon's prior written consent. If any term
      of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then this
      agreement, including all of the remaining terms, will remain in full force and effect as if such invalid or
      unenforceable term had never been included. In any action or proceeding to enforce rights under this Agreement,
      the prevailing party will be entitled to recover costs and attorney's fees.
    </p>
  </div>
</div>
