import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';

@Injectable()
export class Globals{
  public pendingTransactions: number = 0; //number of pending requests to API
  public courts: any[] = [
    {name: 'Select Court', cid: -1},
    {name: 'Albertville Municipal Court', cid: 103},
    //{name: 'Brookhaven Municipal Court', cid: 304},
    {name: 'Canton Municipal Court', cid: 206},
    {name: 'Clarksdale Municipal Court', cid: 207},
    {name: 'College Park Municipal Court', cid: 303},
    {name: 'Decatur Municipal Court', cid: 204},
    {name: 'Gautier Municipal Court', cid: 203},
    {name: 'Hernando Municipal Court', cid: 209},
    {name: 'Horn Lake Municipal Court', cid: 202},
    {name: 'Indianola Municipal Court', cid: 211},
    {name: 'Oxford Municipal Court', cid: 210},
    {name: 'Petal Municipal Court', cid: 208},
    {name: 'Picayune Municipal Court', cid: 205},
    {name: 'Suwanee Municipal Court', cid: 1},
    {name: 'Tuscaloosa Municipal Court', cid: 101},
//    {name: 'Test Picayune Municipal Court', cid: 3},
  ];

  constructor(
    public dialog: MatDialog,
  ){
  }

  public resetRequests(): void{
    setTimeout(() => {
      this.pendingTransactions = 0;
    });
  }
  public startTrans(): void{
    setTimeout(() => {
      this.pendingTransactions++;
    });
  }
  public finishTrans(): void{
    setTimeout(() => {
      this.pendingTransactions--;
    });
  }

  public alertModal(title: string, message: string, type: string): any{
    if (!type){
      type = "ok";
    }
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: '90%',
      data: {
        title: title,
        message: message,
        type: type,
      }
    });
    if (type == 'confirm'){
      return dialogRef;
    } else {
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

}
