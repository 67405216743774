<div class="row">
  <div class="col-12">
    <div class="jumbotron">
      <h1 class="h1">Privacy Policy <small>August 4, 2020</small></h1>
      <p class="lead text-justify">By using the court.today website you consent to the collection,
        use and disclosure of your personal information in accordance with this policy.
      </p>
    </div>
  </div>
  <div class="col-12">
    <h2>1. Please read this policy and if you have any questions contact us</h2>
    <p>
      Syscon, Inc. is committed to protecting your privacy. We value the trust customers place in our company.
      Accordingly, Syscon adheres to the highest ethical standards in gathering, using and safeguarding customer
      information that is entrusted to us. This privacy policy tells you about our online collection, use and
      disclosure of personal information. The terms of this policy apply to all personal information collected
      by Syscon, Inc. on the website https://court.today/ .
    </p>
  </div>
  <div class="col-12">
    <h2>2. Collection of Your Non-Personal and Personal Information</h2>
    <p>
      https://court.today/ collects certain kinds of non-personal information, such as the website that referred
      you to us, browser type and language, and access times. We also may collect navigational information,
      including information about the pages you view, the links you click, and other actions taken in connection
      with the site. Limited demographic information, such as the industry you work in, may also be collected
      and may be linked to your personal information.
    </p>
    <p>
      Personal information includes last name, email, phone and date of birth that you provide in order to search
      public records, as well as your full name, vehicle details and license plate details retrieved by the search.
    </p>
  </div>
  <div class="col-12">
    <h2>3. Consent of Your Personal Information</h2>
    <p>
      By submitting personal information to us, you acknowledge and agree that you have consented to the collection,
      use and disclosure practices set out in this Privacy Policy as they relate to your personal information.
    </p>
  </div>
  <div class="col-12">
    <h2>4. Use of Your Personal Information</h2>
    <p>
      https://court.today/ collects and uses your personal information to:
    </p>
    <ul>
      <li>retrieve your public records;</li>
      <li>provide customer service;</li>
      <li>perform research and analysis aimed at improving our products, services and technologies, including
        sending you surveys;</li>
      <li>to display content that is customized to your interests and preferences;</li>
      <li>to communicate with you about your transactions, new products and services including transaction-related
        communications such as welcome letters and activity confirmations;</li>
    </ul>
    <p>
      Personal information collected by https://court.today/ is stored and processed in the United States. If you
      would like to know more about our privacy practices or if you would like to exercise any of your personal
      information protection rights (including the right to have your personal information deleted), please contact us.
    </p>
  </div>
  <div class="col-12">
    <h2>5. Use of Web Technologies</h2>
    <p>
      We or our 3rd party partners (for example, Google Analytics) may use web technologies, such as cookies and
      single-pixel gifs (also known as web beacons) on our websites and in promotional e-mail messages or newsletters.
    </p>
  </div>
  <div class="col-12">
    <h2>6. Disclosing Your Personal Information</h2>
    <p>
      Except as set out in this privacy policy, https://court.today/ does not disclose, rent, sell or exchange your
      personal information to any third-parties.
    </p>
    <p>
      We limit access to customer information to Syscon, Inc. employees on a need-to-know basis. Syscon educates its
      employees about Syscon policies and practices in regard to safeguarding customer information; preventing its
      unauthorized access, use or disclosure; and ensuring its proper handling.
    </p>
    <p>
      We may disclose your personal information to third-party service providers who perform services on our behalf.
      For example, we may hire other companies to provide data storage, to host websites, to assist in marketing,
      to conduct audits, etc. Those third-party service providers (i) shall be permitted to obtain only the personal
      information they need to provide the service, (ii) must protect personal information to the same extent as
      https://court.today/ , and (iii) shall be prohibited from using it for any other purpose.
    </p>
    <p>
      Information about our customers, including personal information, may be disclosed as part of any merger,
      acquisition, or sale of the company and/or its assets, as well as in the unlikely event of insolvency,
      bankruptcy, or receivership, in which personal information would be transferred as one of the business
      assets of the company. We reserve the right to disclose your personal information if required or permitted
      to do so by law.
    </p>
  </div>
  <div class="col-12">
    <h2>7. Security of Your Personal Information</h2>
    <p>
      The security of your personal information is important to us. Syscon makes all reasonable efforts to ensure
      that the customer information it maintains is accurate, timely and secure. Syscon enforces its policies in
      regard to gathering, access and use of customer information by its employees and authorized third parties.
      We follow generally accepted industry standards to help protect your personal information.
    </p>
    <p>
      No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore,
      while we strive to protect your personal information, we cannot guarantee its absolute security.
    </p>
  </div>
  <div class="col-12">
    <h2>8. External Links</h2>
    <p>
      We may link to websites, including those of our subsidiaries and third-party content providers, that have
      different privacy policies and practices from those disclosed here. We assume no responsibility for the
      policies or practices of such linked sites, and encourage you to become acquainted with them prior to use.
    </p>
  </div>
  <div class="col-12">
    <h2>9. Usage by Minors</h2>
    <p>
      We do not intend to solicit or collect personal information from anyone under the age of 18. If you are
      under 18, do not use or enter information on this site.
    </p>
  </div>
  <div class="col-12">
    <h2>10. Changes to This Privacy Policy</h2>
    <p>
      We reserve the right to change the terms of this privacy policy at any time. If there are material changes
      to this statement or in how court.today will use your personal information, we will notify you by
      prominently posting a notice of such changes here or on our home page, or by sending you an email. Please
      check this page before each transaction to learn of any changes in our privacy policy.
    </p>
  </div>
</div>
