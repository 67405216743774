<nav class="navbar navbar-expand-xs navbar-dark bg-primary">
  <a class="navbar-brand" href="http://syscononline.com" style="top: -10px;">
    <img alt="SysconOnline.com" src="assets/syscon2-transparent-56.png" title="Syscon Inc."
    style="height: 50px;margin-top:-14px;margin-bottom:-12px;">
  </a>
  <ul class="navbar-nav nav nav-pills mr-auto">
    <li class="nav-item">
      <a class="nav-link menu-hover" routerLink="/search"><i class="fa fa-fw fa-search fa-lg"></i> Search Courts</a>
    </li>
  </ul>
</nav>
<div class="container-fluid">
  <mat-progress-bar class="d-print-none" mode="indeterminate" [hidden]="globals.pendingTransactions < 1"></mat-progress-bar>
  <router-outlet></router-outlet>
</div>
