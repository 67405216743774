export class SearchForm {
  lname: string;
  dobDate: Date;
  dob: string;
  dob2: string;
  dl: string;
  ticket: string;
  phone: string;
  email: string;
  recaptchaStr: string;

  constructor(){
    this.lname = "";
    this.dobDate = new Date();
    this.dob = "";
    this.dob2 = "";
    this.dl = "";
    this.ticket = "";
    this.phone = "";
    this.email = "";
    this.recaptchaStr = "";
  }
}
