import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Globals } from '../classes/globals';
import { ApiService } from '../api.service';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';
import { Case } from '../classes/case';
import { SearchForm } from '../classes/searchForm';
import { MatVerticalStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  public destroySubject$: Subject<void> = new Subject();
  public selCourt: any;
  public cases: Case[] = [];
  public search: SearchForm = new SearchForm();
  @ViewChild('stepper') stepper:  MatVerticalStepper;

  constructor(
    private apiService: ApiService,
    public globals: Globals,
  ) { }

  ngOnInit(): void {
    this.selCourt = this.globals.courts[0];
  }
  ngOnDestroy() {
    this.destroySubject$.next();
    this.globals.resetRequests();
  }
  public onSubmitClick(captchaRef: any): void {
    this.cases = [];
    if (this.search.recaptchaStr && this.search.recaptchaStr.length > 0) {
      captchaRef.reset();
    }
    captchaRef.execute();
  }
  public resolved(captchaResponse: string): void {
    this.search.recaptchaStr = captchaResponse;
    if (this.search.recaptchaStr) {
      this.searchCases(this.search);
    }
  }
  searchCases(search: SearchForm): void{
    let message: string = "";
    if (!(this.selCourt.cid > 0)){
      message += "Please select Court!";
    }
    if (!(search.lname.length > 1)){
      message += " Please enter Last Name!";
    }
    if (message.length < 1){
      let mm: number = search.dobDate.getMonth() + 1;
      let dd: number = search.dobDate.getDate();
      search.dob = [
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
        search.dobDate.getFullYear(),
      ].join('');
      search.dob2 = [
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
        search.dobDate.getFullYear(),
      ].join('/');

      this.apiService.getUserCases(search, this.selCourt.cid, search.lname, search.dob, search.dl, search.ticket)
      .pipe(takeUntil(this.destroySubject$)).subscribe(data => {
        this.cases = data;
        setTimeout(() => {
          this.stepper.next();
        });
      });
    } else {
      this.globals.alertModal("Error", message, "ok");
    }
  }

  public printCases(): void{
    window.print();
  }

}
