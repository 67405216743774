<h2 mat-dialog-title><i class="fas"
[ngClass]="{'fa-times-circle': data.title == 'Error', 'fa-check-circle': data.title == 'Success', 'fa-question-circle': data.title == 'Question'}"
></i> {{data.title}}</h2>
<mat-dialog-content>
  <div class="alert"
  [ngClass]="{'alert-danger': data.title == 'Error', 'alert-success': data.title == 'Success'}"
  role="alert">{{data.message}}</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close cdkFocusInitial *ngIf="data.type == 'ok'">OK</button>
  <button mat-raised-button mat-dialog-close="1" cdkFocusInitial *ngIf="data.type == 'confirm'">Yes</button>
  <button mat-raised-button mat-dialog-close="0" *ngIf="data.type == 'confirm'">No</button>
</mat-dialog-actions>
