<div class="jumbotron jumbotron-bgimg">
  <h1>Court Docket Search</h1>
</div>
<mat-vertical-stepper linear="true" #stepper>
  <mat-step [completed]="cases.length > 0" editable="false">
    <ng-template matStepLabel>Find My Cases</ng-template>
    <div class="row">
      <div class="col-12 form-group">
        <form (ngSubmit)="onSubmitClick(captchaRef)" #searchForm="ngForm">
          <div class="row">
            <div class="col-12 form-group form-field-container">
              <mat-form-field>
                <mat-label>Participating Courts</mat-label>
                <mat-select placeholder="Select Court" [(ngModel)]="selCourt" name="selCourt" required>
                  <mat-option *ngFor="let court of globals.courts" [value]="court">
                    {{court.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6 form-group form-field-container">
              <mat-form-field>
                <input matInput placeholder="Last Name" [(ngModel)]="search.lname" name="lname" required>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-md-6 form-group form-field-container">
              <mat-form-field>
                <input matInput [matDatepicker]="dp" placeholder="Date of Birth"
                [(ngModel)]="search.dobDate" name="dp" required>
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <re-captcha
          #captchaRef="reCaptcha"
          (resolved)="resolved($event)"
          size="invisible"></re-captcha>
          <button mat-raised-button color="primary" type="submit"
          [disabled]="!searchForm.form.valid || globals.pendingTransactions > 0 || selCourt.cid < 1">Find My Cases</button>
        </form>
      </div>
    </div>
  </mat-step>
  <mat-step editable="false">
    <ng-template matStepLabel>My Court Dates</ng-template>
    <div class="row" *ngIf="cases.length > 0">
      <div class="col-12">
        <h2>{{cases[0].fullName}} <small>{{selCourt.name}}</small></h2>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" *ngFor="let case of cases">
        <div class="card bg-light mb-3">
          <div class="card border-dark">
            <div class="card-header" [ngClass]="{'navy_blue text-white': case.mustAppear != 'N'}">
              {{case.courtDate}} <span [hidden]="case.mustAppear == 'N'">Please Contact The Court For More Information</span>
              <span [hidden]="case.mustAppear != 'N'" *ngIf="selCourt.cid > 100">
                <a href="https://www.citationpayments.com/court/form_1.php?courtID={{selCourt.cid}}&caseNo={{case.ticketNumber}}&DOB={{search.dob2}}"
                target="_blank"  *ngIf="selCourt.cid > 101">
                  Pay Online {{case.amountDue | currency}}
                </a>
                <a href="https://www.ncourt.com/x-press/x-onlinepayments.aspx?Juris=94043F1F-84DB-449F-A220-0FCCB1969991"
                target="_blank"  *ngIf="selCourt.cid == 101">
                  Pay Online {{case.amountDue | currency}}
                </a>
              </span>
            </div>
            <div class="card-body text-dark">
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Ticket
                  <span class="badge badge-light">{{case.ticketNumber}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Violation
                  <span class="badge badge-light">{{case.description}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Violation Date
                  <span class="badge badge-light">{{case.violationDate_full}}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="case.vehicle">
                  Vehicle
                  <span class="badge badge-light">{{case.vehicle}} {{case.tagNumber}} {{case.tagState}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <button mat-raised-button type="button" color="primary" (click)="printCases()">Print Cases</button>
      </div>
    </div>
  </mat-step>
</mat-vertical-stepper>
