import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Globals } from './classes/globals';
import { GeneralAPIData } from './classes/generalAPIData';
import { Case } from './classes/case';

export const SUCCESS = 1000000;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private isLog: boolean = true;
//  private apiURL: string = "https://test.syscononline.com/api/v1/";
  private apiURL: string = "https://court.syscononline.com:4444/api/v1/";

  constructor(
    private http: HttpClient,
    private globals: Globals,
  ) { }

  private log(str: string, data: any): void{
    if (this.isLog){
      if (data.token){
        data.token = 'hidden';
      }
      console.log(str, data);
    }
  }
  private showErrorMessage(err: any, errStr: string): void{
    this.globals.finishTrans();
    let errMessage: string = err.message;
    if (err.error && err.error.message){
      errMessage += err.error.message;
    }
    this.globals.alertModal("Error", errStr + errMessage, "ok");
  }

  getUserCases(obj: any, courtID: number, last: string, dob: string, dl: string, ticket: string):  Observable<Case[]>{
    this.globals.startTrans();
    let url = this.apiURL + `opensearch/${courtID}/dob/${dob}/lname/${last}`;
    if (dl && dl.length > 0){
      url += `/dl/${dl}`;
    }
    if (ticket && ticket.length > 0){
      url += `/ticket/${ticket}`;
    }
    return this.http.post<GeneralAPIData>(url, obj).pipe(
      map(data => {
        this.globals.finishTrans();
        if (data.code === SUCCESS){
          console.log('getUserCases()', data.courtCases);
          return data.courtCases;
        } else {
          this.globals.alertModal("Error", data.message, "ok");
        }
        return [];
      }),
      catchError((err, caught) => {
        this.log("getUserCases catchError", err);
        this.showErrorMessage(err, "Could not retrieve user cases: ");
        return of([]);
      })
    );
  }

}
