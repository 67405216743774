import { Component, OnInit, OnDestroy } from '@angular/core';
import { Globals } from './classes/globals';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy {
  public destroySubject$: Subject<void> = new Subject();

  constructor(
    public globals: Globals,
  ){

  }
  ngOnInit(): void {
  }
  ngOnDestroy(){
    this.destroySubject$.next();
    this.globals.resetRequests();
  }

}
